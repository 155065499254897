import React from 'react';
import { Grid } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Carousel from 'react-material-ui-carousel';
import ScrollAnimation from 'react-animate-on-scroll';
import background from '../../images/background.jpg';
import lineDashedDown from '../../images/line-dashed-down.png';
import curlyArrow from '../../images/curly-arrow.png';
import arrowSingleDown from '../../images/arrow-single-down.png';
import pdxHalloweenMobileOpenMenu from '../../images/pdx-halloween-iphone-se-menu-open.png';
import pdxHalloweenMobile from '../../images/pdx-halloween-iphone-se.png';
import pdxHalloweenLoggedInDesktop from '../../images/pdx-halloween-logged-in-events.png';
import bangleCountdown from '../../images/bangle-js-countdown.png'
import placegoldenImage from '../../images/placegolden.png';
import curbeeMobileImage from '../../images/curbee-mobile.png';
import curbeeMobileAddFind from '../../images/curbee-mobile-add-find.png';
import curbeeMobileListings from '../../images/curbee-mobile-listings.png';
import curbeeMobileCategories from '../../images/curbee-mobile-categories.png';
import vibeShareImage from '../../images/vibe-share.png';
import vibeShareMixtapes from '../../images/vibe-share-mixtapes.png';
import vibeShareMixtapeDetail from '../../images/vibe-share-mixtape-detail.png';
import postcatImage from '../../images/Postcat-fry-quotes.png';

const useStyles = makeStyles({
  homeContainer: {
    backgroundImage: `url(${background})`,
    backgroundSize: "cover",
    backgroundPosition: "center center",
    fontFamily: "Montserrat",
    position: "absolute",
    top: 0,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    textAlign: "center",
    padding: "275px 0px 0px 0px"
  },
  header1: {
    margin: "20px",
    padding: "10px",
    color:"darkslategray",
    fontSize: "3rem",
    fontWeight: "bolder"
  },
  personalIntroContainer: {
    margin: "150px 0px 20px",
    padding: "10px",
    opacity: "80%",
    fontSize: "larger",
    fontWeight: "bolder",
  },
  projectsIntroContainer: {
    margin: "20px",
    padding: "10px",
    opacity: "80%",
    fontWeight: "bolder"
  },
  projectsContainer: {
    margin: "auto",
    padding: 10
  },
  project: {
    backgroundColor: "cadetblue",
    color: "white",
    fontSize: "larger",
    borderRadius: "10px",
    opacity: "100%",
    margin: "20px auto",
    padding: "10px"
  },
  gridItemsContainer: {
    overflowX: "hidden",
  },
  projectImage: {
    maxWidth: "100%",
    height: "400px"
    // maxHeight: 500
  },
  carouselImage: {
    maxWidth: "100%",
    height: "400px"
    // minHeight: 100,
    // maxHeight: 500
  },
  lineDashedDown: {
    height: "300px",
    margin: "100px 0px"
  },
  curlyArrow: {
    height: "400px",
    margin: "100px 0px"
  },
  arrowSingleDown: {
    height: "100px"
  }
});

const Home = () => {
  const classes = useStyles();
  return (
    <div className={classes.homeContainer}>

    <ScrollAnimation animateIn="fadeIn" animateOut="fadeOut">
      <div className={classes.header1}>Christiane Merritt</div>
    </ScrollAnimation>

      <div>
        <img src={lineDashedDown} alt="dashed vertical line" className={classes.lineDashedDown}/>
      </div>

      <ScrollAnimation animateIn="fadeIn">
        <p className={classes.personalIntroContainer}>
          Hi, I'm Christiane.
          <br />
          <br />
          A full-stack software engineer based in Portland, OR.
        </p>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <p className={classes.projectsIntroContainer}>
        Please take a look at some of my recent projects:
        </p>
      </ScrollAnimation>

      <ScrollAnimation animateIn="fadeIn">
        <div>
          <img src={curlyArrow} alt="curling downward pointing arrow" className={classes.curlyArrow}/>
        </div>
      </ScrollAnimation>

      <Grid container spacing={3}>

        <div className={classes.gridItemsContainer}>
          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>Portland Halloween Event Finder</h3>
              <Carousel>
              <img src={pdxHalloweenMobile} alt="Halloween event finder mobile" className={classes.carouselImage}/>
              <img src={pdxHalloweenMobileOpenMenu} alt="Halloween event finder mobile open menu" className={classes.carouselImage}/>
              <img src={pdxHalloweenLoggedInDesktop} alt="Halloween event finder desktop" className={classes.carouselImage}/>
              </Carousel>
            <br />
            Halloween event finder built for Portland meetup group. Users can sign up and log in, add events, save events to a wishlist, and manage their wishlist. Anyone can see list of events. Built with React using Material-UI for responsive and mobile-friendly design, using Supabase to handle user authorization, authentication, and database.
            <br />
            <br />
            <a href="https://portlandhalloween.com/">Deployed site</a>
            <br />
            <br />
            GitHub <a href="https://github.com/cmmerritt/halloween-event-finder">repo</a> 
          </Grid>

          <ScrollAnimation animateIn="fadeIn">
            <div>
              <img src={arrowSingleDown} alt="single down arrow wedge" className={classes.arrowSingleDown} />
            </div>
          </ScrollAnimation>

          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>Apps for Bangle.js Programmable Smart Watch</h3>
            <img src={bangleCountdown} alt="Bangle date countdown emulator screenshot" className={classes.projectImage} />
            <br />
            Apps developed in JavaScript for use with the <a href="https://www.espruino.com/Bangle.js2">Bangle.js 2 smart watch</a>. Currently developing date countdown timer and knitting row counter. Code can be tested by using the Bangle.js 2 watch emulator with the <a href="https://www.espruino.com/ide/">Espruino IDE</a>. 
            <br />
            <br />
            GitHub <a href="https://github.com/cmmerritt/BangleApps/tree/master/apps/cmmerrittapps/datecountdowntimer">Countdown timer repo</a> 
          </Grid>

          <ScrollAnimation animateIn="fadeIn">
            <div>
              <img src={arrowSingleDown} alt="single down arrow wedge" className={classes.arrowSingleDown} />
            </div>
          </ScrollAnimation>

          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>PlaceGolden</h3>
            <img src={placegoldenImage} alt="PlaceGolden screenshot" className={classes.projectImage} />
            <br />
            Placeholder image service for fans of the Golden Girls. Responsive design built with React and Material-UI. (In progress)
            <br />
            <br />
            <a href="https://placegolden-app.netlify.app/">Deployed site</a>
            <br />
            <br />
            GitHub <a href="https://github.com/cmmerritt/placegolden">front end</a> & <a href="https://github.com/cmmerritt/placegolden-pic">back end</a>
          </Grid>

          <ScrollAnimation animateIn="fadeIn">
            <div>
              <img src={arrowSingleDown} alt="single down arrow wedge" className={classes.arrowSingleDown} />
            </div>
          </ScrollAnimation>

          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>Curbee</h3>
            <Carousel>
              <img src={curbeeMobileImage} alt="Curbee mobile home page screenshot" className={classes.carouselImage}/>
              <img src={curbeeMobileListings} alt="Curbee mobile listings page screenshot" className={classes.carouselImage}/>
              <img src={curbeeMobileAddFind} alt="Curbee mobile add find page screenshot" className={classes.carouselImage}/>
              <img src={curbeeMobileCategories} alt="Curbee mobile add find page category dropdown screenshot" className={classes.carouselImage}/>
            </Carousel>
            <br />
            Discover and share curbside finds. Built in a one-week sprint with a remote team of six. Mobile-first design with two front ends (React and Twilio), image storage with Cloudinary, and location services with the MapQuest API.
            <br />
            <br />
            <a href="https://www.curbee.app/">Deployed site</a>
            <br />
            <br />
            GitHub <a href="https://github.com/curbee-by-curbees">repos</a>
          </Grid>

          <ScrollAnimation animateIn="fadeIn">
            <div>
              <img src={arrowSingleDown} alt="single down arrow wedge" className={classes.arrowSingleDown} />
            </div>
          </ScrollAnimation>

          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>VibeShare</h3>
            <Carousel>
              <img src={vibeShareImage} alt="VibeShare home page screenshot" className={classes.carouselImage}/>
              <img src={vibeShareMixtapes} alt="VibeShare mixtapes page screenshot" className={classes.carouselImage}/>
              <img src={vibeShareMixtapeDetail} alt="VibeShare mixtape detail screenshot" className={classes.carouselImage}/>
            </Carousel>
            <br />
            Package and customize YouTube playlists to send to friends. Inspired by mixtapes of the '80s and '90s, VibeShare uses the YouTube API to fetch and display user playlists, while the front end allows users to choose a visual theme and write liner notes before sending their virtual mixtape to anyone in the world. VibeShare was developed remotely with a team of four over a four-day sprint.
            <br />
            <br />
            Built with: React, CSS, Express, PostgreSQL, Heroku, Netlify
            <br />
            <br />
            <a href="https://naughty-pike-f90005.netlify.app/">Deployed site</a>
            <br />
            <br />
            GitHub <a href="https://github.com/tislais/Vibe-Share-Front-End">front end</a> & <a href="https://https://github.com/tislais/Vibe-Share-Server">back end</a>
          </Grid>

          <ScrollAnimation animateIn="fadeIn">
            <div>
              <img src={arrowSingleDown} alt="single down arrow wedge" className={classes.arrowSingleDown} />
            </div>
          </ScrollAnimation>

          <Grid item xs={10} sm={6} md={6} className={classes.project}>
            <h3>Postcat</h3>
            <img src={postcatImage} alt="Postcat screenshot" className={classes.projectImage}/>
            <br />
            Test your REST(ful API): just for fun, a Postman clone. 
            <br />
            <br />
            <i>Postcat.</i>
            <br />
            <br />
            <a href="https://postcat.netlify.app/">Deployed site</a>
            <br />
            <br />
            GitHub <a href="https://github.com/cmmerritt/postcat">repo</a>
          </Grid>
        </div>
      </Grid>


    </div>
    /* <a href='https://www.freepik.com/vectors/pattern'>Pattern vector created by rawpixel.com - www.freepik.com</a> */

    // https://flyclipart.com/download-png#curved-arrow-with-broken-line-690733.png

    // https://flyclipart.com/download-png#dashed-lines-690740.png

    // https://flyclipart.com/curly-arrow-clip-art-transparent-background-curly-arrow-clipart-680499

    // https://flyclipart.com/arrow-down-thn-png-and-vector-for-free-download-thin-arrow-png-406589#

  );
}

export default Home;

