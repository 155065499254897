import './App.css';
import { ThemeProvider, createTheme } from '@material-ui/core/styles';
import Home from './components/home/Home';

const montserrat =  "'Montserrat', sans-serif";

const theme = createTheme({
  typography: {
    fontFamily: montserrat,
  },
})

const App = () => {
  return (
    <>
      <ThemeProvider theme={theme}>
        <Home />
      </ThemeProvider>
    </>
  );
}

export default App;
